.bisnis {
  display: grid;
  grid-gap: 88px;
}

.bisnis-card {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -24px;
  min-height: 400px;

  & > div {
    width: 50%;
    padding: 0px 24px;
  }

  .title {
    font-size: 24px;
    font-weight: 800;
  }

  .tagline {
    font-size: 16px;
    color: $grey3;
    margin-bottom: 24px;
  }

  .desc {
    margin-bottom: 40px;
  }
}
