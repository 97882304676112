.pd-header {
  position: relative;
  margin-top: 86px;

  @media (max-width: 768px) {
    margin-top: 48px;
  }

  .img-container {
    padding-bottom: 42%;

    @media (max-width: 768px) {
      padding-bottom: 67%;
    }
  }

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &-name {
    position: absolute;
    color: #fff;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;

    @media (max-width: 768px) {
      top: 50%;
      width: 100%;
    }

    @media (max-width: 600px) {
      position: relative;
      color: #000;
      top: 0;
      left: 0;
      transform: none;
      padding: 32px 16px;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  &-address {
    font-size: 16px;
    margin-bottom: 32px;

    @media (max-width: 600px) {
      font-size: 14px;
      color: #8d8d8d;
    }
  }
}

.section-fasilitas {
  padding-top: 88px;
  padding-bottom: 88px;

  @media (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
  }

  .fc-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -16px;

    & > div {
      &:first-child {
        width: 40%;

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 40px;
        }
      }
      width: 30%;
      padding: 0px 16px;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .fc-title {
    font-size: 24px;
    margin-bottom: 24px;

    span {
      font-weight: 800;
    }

    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  .fc-subtitle {
    font-weight: 800;
  }

  .fc-list-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.section-gallery {
  margin-bottom: 80px;

  .section-image-grid {
    grid-template-columns: 1fr 1fr;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .section-image-title {
    margin-bottom: 16px;
    font-weight: 700;
  }

  .section-title {
    margin-bottom: 32px;
    display: block;
  }
  & > div {
    // display: flex;
    // flex-wrap: wrap;
    // margin: 0px -16px;

    img {
      border-radius: 8px;
      transition: 0.3s ease-in-out;

      @media (max-width: 768px) {
        width: 50%;
        height: 360px;
      }

      @media (max-width: 600px) {
        height: 220px;
      }

      &:hover {
        transition: 0.3s ease-in-out;
        transform: translateY(-7px);

        img {
          box-shadow: 0px 4px 10px rgba(33, 68, 87, 0.2);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        transition: 0.3s ease-in-out;

        @media (max-width: 600px) {
          border-radius: 2px;
        }
      }

      .title {
        background-color: rgba(13, 53, 65, 0.5);
        position: absolute;
        padding: 16px;
        font-size: 16px;
        font-weight: 500;
        bottom: 0;
        text-align: center;
        width: calc(100% - 32px);
        color: #fff;

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }
  }
}

.button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  width: 50%;
  margin: auto;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.interior-galeri-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;

  img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.open-image-button {
  position: relative;
  cursor: pointer;

  .image-info {
    position: absolute;
    bottom: 24px;
    left: 0;
    background-color: rgba(13, 53, 65, 0.5);
    padding: 16px;
    text-align: right;
    width: 80%;
    color: #fff;

    .title {
      font-weight: 800;
      font-size: 16px;
      margin-bottom: 4px;
    }

    .desc {
      font-size: 12px;
    }

    button {
      display: none;
    }

    @media (max-width: 600px) {
      width: 100%;
      text-align: left;
      bottom: 0;

      button {
        display: block;
      }

      .desc {
        margin-bottom: 16px;
      }
    }
  }
}
