@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

@import "./variables";
@import "./base";
@import "./component";
@import "./page";
@import "./shared.scss";
@import "./overides.scss";
@import "./home.scss";
@import "./proyekDetail";
@import "./bisnis";
